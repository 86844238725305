import { render, staticRenderFns } from "./wide.vue?vue&type=template&id=5b2df6cc&lang=pug&"
import script from "./wide.vue?vue&type=script&lang=js&"
export * from "./wide.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/codebuild/output/src4114787516/src/frontend/components/Header.vue').default,Body: require('/codebuild/output/src4114787516/src/frontend/components/Body.vue').default,Footer: require('/codebuild/output/src4114787516/src/frontend/components/Footer.vue').default})
