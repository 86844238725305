import { render, staticRenderFns } from "./Plan.vue?vue&type=template&id=7b8fd5a0&scoped=true&lang=pug&"
import script from "./Plan.vue?vue&type=script&lang=js&"
export * from "./Plan.vue?vue&type=script&lang=js&"
import style0 from "./Plan.vue?vue&type=style&index=0&id=7b8fd5a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8fd5a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DrugsMonthlyByDrug: require('/codebuild/output/src4114787516/src/frontend/components/DrugsMonthlyByDrug.vue').default,PlanProvidersList: require('/codebuild/output/src4114787516/src/frontend/components/PlanProvidersList.vue').default})
